import { mImages } from "../assets/images";


export const products = [
  {
    id: 1,
    name: "1/4-inch Yellow 15lb Resistance Loop Band - Ideal for Stretching & Upper Body Workouts",
    price: 9.99,
    image: mImages.product2,
  },
  {
    id: 2,
    name: "1/2-inch Red 30lb Resistance Loop Band - Perfect for Stretching & Upper Body Exercises",
    price: 14.99,
    image: mImages.product9,
  },
  {
    id: 3,
    name: "7/8-inch Black 50lb Resistance Loop Band - Great for Pull-Ups & Full Body Workouts",
    price: 17.99,
    image: mImages.product10,
  },
  {
    id: 4,
    name: "1 1/8-inch Purple 70lb Resistance Loop Band - Excellent for Pull-Ups & Full Body Workouts",
    price: 19.99,
    image: mImages.product6,
  },
  {
    id: 5,
    name: "1 3/4-inch Green 100lb Resistance Loop Band - Ideal for Pull-Ups & Hip Thrusts",
    price: 24.99,
    image: mImages.product8,
  },
  {
    id: 6,
    name: "Resistance Loop Band Set - 15lb, 30lb, 50lb, 70lb - Complete Workout Package for Stretching, Upper Body, and Full Body Exercises",
    price: 45.99,
    image: mImages.product4,
  },
  {
    id: 7,
    name: "Resistance Loop Band Set - 30lb, 50lb, 70lb, 100lb - Complete Workout Package for Stretching, Upper Body, and Full Body Exercises",
    price: 54.99,
    image: mImages.product5,
  },

  {
    id: 8,
    name: "12-Inch Leg & Booty Resistance Bands Deluxe Set - Full Range 15lb to 70lb (4 Bands)",
    price: 19.99,
    image: mImages.product3,
  },
  {
    id: 9,
    name: "2-inch Non-Slip Fabric Resistance Band",
    price: 14.99,
    image: mImages.product1,
  },
];
