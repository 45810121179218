import React from "react";
import { Navbar } from "../../components/layout";
import { ProductCard } from "../../components/common";
import { products } from "../../utils/products";

export const Shop = () => {
  return (
    <div className="resContainer">
      <Navbar />
      <div className="pt-7 pb-10 md:pt-12 md:pb-20">
        <h1
          className={
            "text-[19px] sm:text-[24px] md:text-[35px] font-semibold text-dark"
          }
        >
          Shop
        </h1>
        <div className="mt-4 sm:mt-6 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-3 sm:gap-5">
          {products.map((prod, index) => (
            <div key={index}>
              <ProductCard prod={prod} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
