import React, { useState } from "react";
import {
  useStripe,
  useElements,
  PaymentElement,
} from "@stripe/react-stripe-js";
import { InputField, PrimaryButton, TextAreaField } from "../../common";
import toast from "react-hot-toast";
import { useCart } from "../../../contexts/cartContext";

export const CheckoutForm = () => {
  const [isLoading, setIsLoading] = useState(false);
  const stripe = useStripe();
  const elements = useElements();
  const { clearCart } = useCart();
  // State for user details
  const [userDetails, setUserDetails] = useState({
    name: "",
    email: "",
    address: "",
    city: "",
    state: "",
    zip: "",
  });

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setIsLoading(true);
    const result = await stripe.confirmPayment({
      //`Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {
        return_url: `${process.env.REACT_APP_BASE_URL}/checkout/success`,
        payment_method_data: {
          billing_details: {
            name: userDetails.name,
            email: userDetails.email,
            address: {
              line1: userDetails.address,
              city: userDetails.city,
              state: userDetails.state,
              postal_code: userDetails.zip,
            },
          },
        },
      },
    });

    if (!result.error) {
      clearCart();
      setIsLoading(false);
    }

    console.log("result", result);

    if (result.error) {
      toast.error(result.error.message);
      console.log(result.error.message);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserDetails((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  return (
    <form onSubmit={handleSubmit} className="flex-1">
      <h1 className="text-2xl font-semibold text-gray-800">User Details</h1>
      <div className="flex flex-col gap-4 my-5">
        <div className="flex gap-4 items-center flex-wrap">
          <InputField
            label="Name"
            placeholder="Your name"
            required
            name={"name"}
            value={userDetails?.name}
            onChange={handleChange}
          />
          <InputField
            type="email"
            label="Email"
            placeholder="Your email"
            required
            name={"email"}
            value={userDetails?.email}
            onChange={handleChange}
          />
        </div>
        <div className="flex gap-4 items-center flex-wrap">
          <InputField
            label="City"
            placeholder="Your city"
            required
            name={"city"}
            value={userDetails?.city}
            onChange={handleChange}
          />
          <InputField
            label="State"
            placeholder="Your state"
            name={"state"}
            value={userDetails?.state}
            onChange={handleChange}
          />
        </div>
        <InputField
          label="Zip"
          placeholder="Zip code"
          name={"zip"}
          value={userDetails?.zip}
          onChange={handleChange}
        />
        <TextAreaField
          label="Address"
          placeholder="Your Address"
          required
          name={"address"}
          value={userDetails?.address}
          onChange={handleChange}
        />
      </div>
      <div className="mt-8">
        <h1 className="text-2xl font-semibold text-gray-800 mb-5">
          Card Details
        </h1>
        <PaymentElement />
        <PrimaryButton
          title={`${isLoading ? "Processing..." : "Submit"}`}
          disabled={!stripe || isLoading}
          className="max-w-[170px] mt-8"
        />
      </div>
    </form>
  );
};
