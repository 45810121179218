import React from "react";
import { useCart } from "../../../contexts/cartContext";
import { PrimaryButton } from "../../common";
import { NavLink } from "react-router-dom";
import { routes } from "../../../utils/contants";

export const CartSummary = () => {
  const { clearCart, getTotalPrice } = useCart();
  return (
    <div className="w-full lg:w-[302px] sticky top-5">
      <h1 className="text-sm sm:text-xl font-bold text-[#3D3D3D]">Summary</h1>

      <div className="flex flex-row justify-between items-center text-[13px] sm:text-sm font-semibold text-[#3D3D3D] mt-7 sm:mt-10">
        <span>Cart Total</span>
        <span>${getTotalPrice()}</span>
      </div>
      <div className="flex gap-x-3 flex-wrap mt-8">
        <NavLink to={routes.checkout} className={"flex-1"}>
          <PrimaryButton
            title={"Checkout"}
            className={"w-full !rounded-none"}
          />
        </NavLink>
        <button
          className={`flex-1 h-[38px] hover:bg-primary/80 hover:text-white sm:h-[42px] bg-white border-2 border-primary text-primary text-sm font-medium flex gap-1 justify-center items-center`}
          onClick={clearCart}
        >
          Clear cart
        </button>
      </div>
    </div>
  );
};
