import { createBrowserRouter } from "react-router-dom";
import { CartPage, Checkout, Home, Root, Shop } from "./routes";
import ErrorPage from "./error-page";
import { CheckoutSuccess } from "./routes/Success";
import { routes } from "./utils/contants";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    errorElement: <ErrorPage />,
    children: [
      { index: true, element: <Home /> },
      { path: routes.cart, element: <CartPage /> },
      { path: routes.checkout, element: <Checkout /> },
      { path: routes.shop, element: <Shop /> },
      { path: routes.checkoutSuccess, element: <CheckoutSuccess /> },
    ],
  },
]);

export default router;
