import React, { useEffect, useState } from "react";
import { Navbar } from "../../components/layout";
import { CheckoutForm, CheckoutSummary } from "../../components/checkout";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { Loader } from "../../components/common";
import { useCart } from "../../contexts/cartContext";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

export const Checkout = () => {
  const [clientSecret, setClientSecret] = useState("");
  const [error, setError] = useState(null);
  const { getTotalPrice } = useCart();

  useEffect(() => {
    // Amount convert to cents
    const amount = getTotalPrice() * 100;
    const createPaymentIntent = async () => {
      try {
        const response = await fetch(
          "https://api.stripe.com/v1/payment_intents",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
              Authorization: `Bearer ${process.env.REACT_APP_STRIPE_SECRET_KEY}`,
            },
            body: new URLSearchParams({
              amount: amount?.toString(),
              currency: "usd",
            }),
          }
        );

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const data = await response.json();
        if (data.error) {
          throw new Error(data.error.message);
        }

        setClientSecret(data.client_secret);
      } catch (error) {
        setError(error.message);
      }
    };

    createPaymentIntent();
  }, []);

  const options = {
    clientSecret,
  };
  return clientSecret ? (
    <Elements stripe={stripePromise} options={options}>
      <div className="resContainer">
        <Navbar />

        <div className="mt-8">
          <h1 className="text-xl sm:text-2xl md:text-3xl text-gray-800 font-bold leading-tight">
            Checkout
          </h1>
        </div>

        {error && (
          <div className="text-red-500 text-base font-medium mt-5">{error}</div>
        )}

        <div className="mt-5 mb-12 md:my-12 flex flex-wrap gap-x-20 gap-y-10">
          <CheckoutForm />
          <CheckoutSummary />
        </div>
      </div>
    </Elements>
  ) : (
    <Loader />
  );
};
