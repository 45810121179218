import logo from "./logo.png";
import headerImage from "./header.svg";
import product1 from "./product1.jpeg";
import product2 from "./product2.jpeg";
import product3 from "./product3.jpeg";
import product4 from "./product4.jpeg";
import product5 from "./product5.jpeg";
import product6 from "./product6.PNG";
import product7 from "./product7.PNG";
import product8 from "./product8.PNG";
import product9 from "./product9.PNG";
import product10 from "./product10.PNG";

export const mImages = {
  logo,
  headerImage,
  product1,
  product2,
  product3,
  product4,
  product5,
  product6,
  product7,
  product8,
  product9,
  product10,
};
