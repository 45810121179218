import React from "react";
import { useCart } from "../../../contexts/cartContext";
import { RxCross2 } from "react-icons/rx";

export const CartProducts = () => {
  const { cart, removeFromCart, updateQuantity } = useCart();

  return (
    <div className="">
      <h1 className="text-xl hidden sm:block font-bold text-darkblack mb-4">
        Cart
      </h1>
      <div className="md:w-[724px]">
        <div className="w-full h-[61px] flex flex-row items-center mb-4">
          <span className="font-medium text-sm md:text-base text-[#3D3D3D] w-[140px] sm:w-[350px]">
            Item
          </span>
          <span className="font-medium hidden text-sm md:text-base md:table-cell text-[#3D3D3D] w-[14%]">
            Price
          </span>
          <span className="font-medium text-[#3D3D3D] text-sm md:text-base w-[90px] md:w-[20%]">
            Quantity
          </span>
          <span className="font-medium text-[#3D3D3D] text-sm md:text-base md:w-[14%]">
            SubTotal
          </span>
        </div>
        {cart.map((item) => (
          <div key={item.id}>
            <div
              className="w-full h-[61px] flex flex-row items-center relative"
              style={{ paddingBottom: "10px 0" }}
            >
              <span className="flex flex-row items-center cursor-pointer w-[140px] sm:w-[350px]">
                <img
                  src={item.image}
                  alt="Item"
                  className="pr-4 md:pr-10 w-[45px] h-[45px] sm:w-[80px] sm:h-[60px] object-cover"
                />
                <span className="text-[#3D3D3D] text-[13px] sm:text-sm font-medium">
                  {item.name}
                </span>
              </span>
              <span className="text-[#3D3D3D] text-sm font-medium w-[14%] hidden md:table-cell">
                ${item.price}
              </span>
              <span className="text-[#3D3D3D] text-sm font-medium md:w-[20%]">
                <span className="w-[90px] pl-5 md:pl-0 md:w-[114px] md:h-[43px] md:border border-[#EEEEEE] flex">
                  <span
                    className="bg-[#EEEEEE] md:w-[26px] hidden md:flex justify-center items-center cursor-pointer"
                    onClick={() =>
                      updateQuantity(item.id, Math.max(item.quantity - 1, 1))
                    }
                  >
                    -
                  </span>
                  <span className="md:w-[62px] bg-white text-[13px] md:text-sm flex justify-center items-center">
                    {item.quantity}
                  </span>
                  <span
                    className="bg-[#EEEEEE] md:w-[26px] hidden md:flex justify-center items-center cursor-pointer"
                    onClick={() => updateQuantity(item.id, item.quantity + 1)}
                  >
                    +
                  </span>
                </span>
              </span>
              <span className="text-[#3D3D3D] text-[13px] md:text-sm font-medium  md:w-[14%]">
                ${item.quantity * item.price}
              </span>
              <button
                className="absolute -right-2 sm:-right-5 cursor-pointer"
                onClick={() => removeFromCart(item.id)}
              >
                <RxCross2 className="text-red-500 text-xl" />
              </button>
            </div>
            <hr className="w-full h-[1px] text-[#e2e2e2] my-4" />
          </div>
        ))}
      </div>
    </div>
  );
};
