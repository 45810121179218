import { routes } from "./contants";

export const menu = [
  {
    name: "Home",
    path: routes.home,
  },
  {
    name: "Products",
    path: routes.shop,
  },
];
export const footerLinks = [
  {
    name: "Products",
    path: routes.shop,
  },
  {
    name: "About",
    path: routes.aboutUs,
  },
  {
    name: "Contact",
    path: routes.contactUs,
  },
];
