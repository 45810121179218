import React from "react";
import { Navbar } from "../../layout";
import { PrimaryButton } from "../../common";
import { IoIosArrowRoundDown } from "react-icons/io";
import { mImages } from "../../../assets/images";
import { NavLink } from "react-router-dom";

export const HomeHeader = () => {
  return (
    <header className={`w-full md:min-h-screen bg-primary-light`}>
      <div className="z-10 relative min-h-screen">
        <div className="resContainer h-full min-h-screen flex flex-col">
          <Navbar />
          <div className="flex-1 h-full flex justify-center items-center py-8">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-x-28 gap-y-8 items-center">
              <div className="flex flex-col gap-3 sm:gap-6">
                <h1 className="text-xl sm:text-2xl md:text-4xl text-gray-800 font-bold leading-tight">
                  A better way to talk with your customers
                </h1>
                <p className="text-gray-800 text-sm sm:text-base">
                  Discover a new level of interaction and satisfaction with our
                  state-of-the-art communication tools. Whether it's live chat,
                  email, or social media, our platform ensures seamless and
                  effective engagement with your customers, enhancing their
                  experience and boosting your business.
                </p>
                <a href="#productsSlider">
                  <PrimaryButton
                    title="Let's try"
                    className="max-w-[140px]"
                    icon={<IoIosArrowRoundDown />}
                  />
                </a>
              </div>
              <div className="mx-auto md:ml-auto">
                <img src={mImages.headerImage} alt="Shop" width={490} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};
