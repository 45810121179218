import React from "react";
import { IoBagCheckOutline } from "react-icons/io5";
import { useCart } from "../../../contexts/cartContext";
import { PrimaryButton } from "../PrimaryButton";
import toast from "react-hot-toast";

export const ProductCard = ({ prod }) => {
  const { addToCart } = useCart();

  return (
    <div className="mb-5 sm:mb-0 bg-gray-100 hover:bg-primary-light relative cursor-pointer py-2 px-3 rounded-md group">
      <span className="text-[10px] px-2.5 py-1 rounded-[9px] text-white bg-primary">
        New
      </span>
      <div className="w-[200px] h-[170px] mx-auto overflow-hidden py-3 my-2 flex items-center">
        <img
          src={prod?.image}
          alt="Gaming PC"
          width={210}
          height={170}
          className="max-w-[200px] h-[170px] object-contain mx-auto my-2"
        />
      </div>
      <div className="flex justify-between items-start">
        <div className="py-2">
          <p className="text-base text-black font-semibold mt-0.5">
            {prod?.name}
          </p>
          <p className="text-base font-bold text-primary">${prod?.price}</p>
        </div>
        <IoBagCheckOutline className="text-primary text-2xl mt-2" />
      </div>
      <PrimaryButton
        title="Add to cart"
        className={"w-full my-3 bg-black hover:bg-black/70"}
        onClick={() => {
          addToCart(prod);
          toast.success("Product Added to your cart");
        }}
      />
    </div>
  );
};
