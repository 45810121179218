import React, { useEffect } from "react";
import { Navbar } from "../../components/layout";
import { RiSecurePaymentFill } from "react-icons/ri";
import { PrimaryButton } from "../../components/common";
import { NavLink, useNavigate } from "react-router-dom";

export const CheckoutSuccess = () => {
  const navigate = useNavigate();

  useEffect(() => {
    navigate("/checkout/success");
  }, [navigate]);

  return (
    <div className="resContainer">
      <Navbar />

      <div className="flex flex-col gap-6 items-center justify-center my-10 md:mt-12 md:mb-24">
        <RiSecurePaymentFill className="text-primary text-[100px]" />
        <h1 className="text-2xl text-gray-700 font-semibold text-center">
          Your Order has been placed!
        </h1>
        <NavLink to="/" className="w-full">
          <PrimaryButton
            title="Back to home"
            className="max-w-[220px] !rounded-none mx-auto block"
          />
        </NavLink>
      </div>
    </div>
  );
};
