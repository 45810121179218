import React, { useState, useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { PrimaryButton } from "../../common";
import { mImages } from "../../../assets/images";
import { menu } from "../../../utils/data";
import { routes } from "../../../utils/contants";
import { IoCartOutline } from "react-icons/io5";
import { useCart } from "../../../contexts/cartContext";

export const Navbar = () => {
  const { cart } = useCart();
  const [isSticky, setIsSticky] = useState(false);
  let location = useLocation();

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className={`transition-all duration-500`}>
      <nav className={`flex justify-between items-center py-3 md:py-4`}>
        <NavLink to={routes.home}>
          <img src={mImages.logo} alt="Logo" className="w-[70px] sm:w-[90px]" />
        </NavLink>

        <div className={`transition-all duration-500 p-5 w-full md:p-0`}>
          <ul
            className={`flex gap-x-5 sm:gap-x-8 items-center justify-end sm:mr-8 `}
          >
            {menu.map((item, index) => {
              const isActive = location.pathname === item.path;

              return (
                <li key={item.name + index}>
                  <NavLink to={item.path}>
                    <span
                      className={`${
                        isActive ? "text-primary" : `text-gray-700`
                      } text-sm sm:text-base font-medium hover:text-primary`}
                    >
                      {item.name}
                    </span>
                  </NavLink>
                </li>
              );
            })}
          </ul>
        </div>
        <div className="flex gap-5 items-center">
          <NavLink
            to={"/shop"}
            className="hidden sm:block min-w-[75px] sm:min-w-[90px]"
          >
            <PrimaryButton title="Shop" className="w-full" />
          </NavLink>
          <NavLink to={routes.cart}>
            <button className="relative">
              <IoCartOutline className="text-3xl text-primary" />
              {cart?.length > 0 && (
                <span className="absolute -top-1 -right-1 bg-primary text-white rounded-full text-[10px] w-4 h-4 inline-flex justify-center items-center font-medium">
                  {cart?.length}
                </span>
              )}
            </button>
          </NavLink>
        </div>
      </nav>
    </div>
  );
};
