"use client";

import React from "react";
import { FaArrowLeftLong, FaArrowRightLong } from "react-icons/fa6";
import Slider from "react-slick";
import { ProductCard } from "../../common";
import { products } from "../../../utils/products";

export const HomeProductsSlider = () => {
  const settings = {
    infinite: true,
    speed: 500,
    dots: false,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: 0,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <section className={"py-12 lg:py-24 bg-white"} id="productsSlider">
      <div className="resContainer">
        <p
          className={
            "text-sm text-primary uppercase font-medium mt-1 text-center"
          }
        >
          Latest
        </p>
        <h1
          className={
            "text-[22px] sm:text-[28px] md:text-[40px] font-semibold text-dark text-center"
          }
        >
          Products
        </h1>
        <p className={"text-sm sm:text-base  text-gray-700 mt-2 text-center"}>
          Check out Some of our latest products.
        </p>
      </div>

      <div className="resContainer">
        <div className="mt-12 ">
          <Slider {...settings}>
            {products.map((prod, index) => (
              <div className="px-3" key={index}>
                <ProductCard prod={prod} />
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </section>
  );
};

// Next Arrow icon Component
const NextArrow = (props) => {
  const { onClick } = props;
  return (
    <div
      className={
        "flex justify-center items-center rounded-full w-[30px] h-[30px] sm:w-[40px] sm:h-[40px] absolute -right-2 lg:-right-16 md:-right-10 top-1/2 translate-y-[-50%] cursor-pointer"
      }
      onClick={onClick}
    >
      <FaArrowRightLong className="text-base sm:text-xl text-indigo-600" />
    </div>
  );
};

// Previous Arrow icon Component
const PrevArrow = (props) => {
  const { onClick } = props;
  return (
    <div
      className={
        "rounded-full w-[30px] h-[30px] sm:w-[40px] sm:h-[40px] absolute -left-2 lg:-left-16 md:-left-10 top-1/2 translate-y-[-50%] cursor-pointer z-[99]"
      }
      onClick={onClick}
    >
      <FaArrowLeftLong className="text-base sm:text-xl text-indigo-600" />
    </div>
  );
};
