import React from "react";
import { Navbar } from "../../components/layout";
import { useCart } from "../../contexts/cartContext";
import { CartProducts, CartSummary } from "../../components/cart";

export const CartPage = () => {
  const { cart } = useCart();

  return (
    <div className="resContainer">
      <Navbar />

      {cart.length === 0 ? (
        <h1 className="text-center font-semibold text-base md:text-2xl py-16 sm:py-24 text-AdmiPrimary">
          Your Cart is Empty
        </h1>
      ) : (
        <section className="my-8">
          <div className="flex flex-row items-start flex-wrap gap-5 md:justify-between">
            <CartProducts />
            <CartSummary />
          </div>
        </section>
      )}
    </div>
  );
};
