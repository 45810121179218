import React from "react";
import { ButtonLoader } from "../ButtonLoader";

export const PrimaryButton = ({
  title,
  loading = false,
  className,
  icon,
  ...rest
}) => (
  <button
    className={`${className} w-full h-[38px] rounded-full hover:bg-primary/80 sm:h-[42px] bg-primary text-white text-sm font-medium flex gap-1 justify-center items-center`}
    {...rest}
  >
    {/* {loading ? <ButtonLoader /> :  title} */}
    {title} <span className="text-xl  text-white">{icon}</span>
  </button>
);
