import React from "react";
import { HomeHeader, HomeProductsSlider } from "../../components/home";

export const Home = () => {
  return (
    <>
      <HomeHeader />
      <HomeProductsSlider />
    </>
  );
};
