import React from "react";

export const TextAreaField = ({ label, rows, placeholder, ...rest }) => {
  return (
    <div className="flex-1">
      {label && (
        <label className="block text-gray-700 mb-1 text-[15px]">{label}</label>
      )}
      <textarea
        rows={rows ?? 3}
        placeholder={placeholder}
        className="w-full py-3 focus:outline-none text-sm rounded-md px-3 border resize-none"
        {...rest}
      />
    </div>
  );
};
