import React from "react";
import { useCart } from "../../../contexts/cartContext";
import { PrimaryButton } from "../../common";
import { NavLink } from "react-router-dom";
import { routes } from "../../../utils/contants";

export const CheckoutSummary = () => {
  const { getTotalPrice, cart } = useCart();
  return (
    <div className="w-full lg:w-[302px] mt-12 lg:mt-0 sticky top-5">
      <h1 className="text-sm sm:text-xl font-bold text-[#3D3D3D]">Summary</h1>

      <div className="flex flex-row justify-between items-center text-[13px] sm:text-sm font-semibold text-[#3D3D3D] mt-7 sm:mt-10">
        <span>Products</span>
        <span>{cart?.length}</span>
      </div>
      <div className="flex flex-row justify-between items-center text-[13px] sm:text-sm font-semibold text-[#3D3D3D] mt-5">
        <span>Products Quantity</span>
        <span>{cart?.reduce((acc, prod) => acc + prod?.quantity, 0)}</span>
      </div>
      <div className="flex flex-row justify-between items-center text-[13px] sm:text-sm font-semibold text-[#3D3D3D] mt-5">
        <span>Total Price</span>
        <span>${getTotalPrice()}</span>
      </div>
      <div className="flex gap-x-3 flex-wrap mt-8">
        <NavLink to={routes.cart} className={"flex-1"}>
          <PrimaryButton
            title={"Back to cart"}
            className={"w-full !rounded-none"}
          />
        </NavLink>
      </div>
    </div>
  );
};
