import React from "react";
import { mImages } from "../../../assets/images";
import { NavLink } from "react-router-dom";
import { routes } from "../../../utils/contants";
import { PrimaryButton } from "../../common/PrimaryButton";
import { FaLocationDot } from "react-icons/fa6";
import { MdEmail, MdLocalPhone } from "react-icons/md";

export const Footer = () => (
  <footer className="pt-10 pb-5 bg-gray-200 border-t border-gray-200">
    <div className="resContainer">
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-y-6 gap-x-12">
        <div className="flex flex-col gap-5">
          <NavLink to={routes.home}>
            <img src={mImages.logo} alt="Logo" width={140} />
          </NavLink>
          <p className="text-sm sm:text-base">
            Discover a new level of interaction and satisfaction with our
            state-of-the-art communication tools. Whether it's live chat, email,
            or social media, our platform ensures seamless and effective
            engagement with your customers
          </p>
          <NavLink to={routes.aboutUs}>
            <PrimaryButton title="About Us" className="max-w-[150px]" />
          </NavLink>
        </div>

        {/* Quick Information */}
        <div>
          <div>
            <h5 className="text-gray-700 text-2xl font-bold">Quick Info</h5>
            <span className="w-20 h-[2px] bg-primary block mt-2" />
          </div>
          <div className="flex flex-col gap-4 mt-10">
            <div className="flex gap-2">
              <FaLocationDot className="text-gray-700 text-xl" />
              <span className="text-sm sm:text-base text-gray-700">
                4900 NE 1st Ter Oakland Park, FL 33334
              </span>
            </div>
            <div className="flex gap-2">
              <MdEmail className="text-gray-700 text-xl" />
              <span className="text-sm sm:text-base text-gray-700">
                support@playfitbands.com
              </span>
            </div>
            <div className="flex gap-2">
              <MdLocalPhone className="text-gray-700 text-xl" />
              <span className="text-sm sm:text-base text-gray-700">
                (754) 242-7030
              </span>
            </div>
          </div>
          <div className="mt-5">
            <h5 className="text-base text-gray-700 font-bold">Work Hours :</h5>
            <p className="text-sm sm:text-base text-gray-700 mt-2">
              Mon - Fri : 9am - 5pm
            </p>
          </div>
        </div>

        {/* Gallery */}
        <div>
          <div>
            <h5 className="text-gray-700 text-2xl font-bold">Gallery</h5>
            <span className="w-20 h-[2px] bg-primary block mt-2" />
          </div>

          <div className="grid grid-cols-3 gap-2.5 mt-10">
            {[
              mImages.product1,
              mImages.product2,
              mImages.product3,
              mImages.product4,
              mImages.product5,
              mImages.product6,
              mImages.product7,
              mImages.product8,
              mImages.product9,
            ].map((img, index) => (
              <img
                key={index}
                src={img}
                alt=""
                width={90}
                height={90}
                className="w-full h-[90px] border border-white"
              />
            ))}
          </div>
        </div>
      </div>
      <hr className="my-5 border-t border-gray-300" />
      <p className="text-gray-700 text-sm sm:text-base text-center">
        Copyright {new Date().getFullYear()} © All Right Reserved.
      </p>
    </div>
  </footer>
);
