import React from "react";

export const InputField = ({ label, type, placeholder, ...rest }) => {
  return (
    <div className="flex-1">
      {label && (
        <label className="block text-gray-700 mb-1 text-[15px]">{label}</label>
      )}
      <input
        type={type ?? "text"}
        placeholder={placeholder}
        className="w-full h-[45px] focus:outline-none text-sm rounded-md px-3 border"
        {...rest}
      />
    </div>
  );
};
